import React from 'react';
import clsx from 'clsx';
import {Spacing} from '@treatwell/design-tokens';

import {Text} from '../Text';
import {Base} from '../../Internal';
import type {Icon} from '../Icon';
import {Inline} from '../../Layout';
import styles from './Button.module.css';
import {InlineLoading} from '../../Internal/InlineLoading';

export interface Props extends React.ComponentPropsWithoutRef<'button'> {
  children: React.ReactNode;
  icon?: Icon;
  iconPosition?: 'left' | 'right';
  buttonStyle?: 'primary' | 'secondary';
  size?: Extract<Spacing, 'sm' | 'md' | 'lg'>;
  fullWidth?: boolean;
  isLoading?: boolean;
}

export const Button = React.forwardRef(
  (
    {
      children,
      disabled,
      isLoading = false,
      type = 'button',
      icon,
      iconPosition = 'left',
      buttonStyle = 'primary',
      size = 'lg',
      fullWidth = false,
      className,
      ...restProps
    }: Props,
    ref?: React.Ref<HTMLButtonElement>
  ) => {
    const buttonStyles = clsx(
      className,
      styles.button,
      styles[buttonStyle],
      styles[size],
      {
        [styles.disabled]: disabled,
        [styles.onLoading]: isLoading,
        [styles.fullWidth]: fullWidth,
      }
    );

    return (
      <Base
        as="button"
        className={buttonStyles}
        type={type}
        forwardRef={ref}
        disabled={disabled}
        {...restProps}
      >
        <Inline justify="center" space="xs">
          {isLoading && <InlineLoading />}

          {!isLoading && (
            <>
              {iconPosition === 'left' && icon}
              <Text type="bodyHeavy">{children}</Text>
              {iconPosition === 'right' && icon}
            </>
          )}
        </Inline>
      </Base>
    );
  }
);

Button.displayName = 'Button';
