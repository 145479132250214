import * as React from 'react';
import {useDelayMount} from '../../Atoms/Loading/useDelayMount';

import styles from './InlineLoading.module.css';
interface Props {
  delay?: number;
}

export const InlineLoading = ({delay = 0}: Props) => {
  const mounted = useDelayMount(delay);
  // Extra `span` container prevents a parent with flex (like an Inline)
  // from overriding the margin of the dots
  const component = (
    <span>
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
    </span>
  );

  if (delay === 0) {
    return component;
  }
  return <>{mounted && component}</>;
};
