import React from 'react';

import {BadgeSize, ColorPair, ColorPairTeal} from '../../Internal/Badge';
import {
  IconAllTreatments,
  IconBeard,
  IconBody,
  IconFace,
  IconHair,
  IconHairRemoval,
  IconMassage,
  IconMedicalDental,
  IconNails,
  IconSpa,
} from '../Icon';
import {IconBadge} from '../IconBadge';

type IconProps = {
  size: 16 | 24;
};
type Icon = (props: IconProps) => React.ReactElement<React.ReactSVGElement>;

const icons: {
  treatment: Record<number, Icon>;
  venue: Record<number, Icon>;
} = {
  treatment: {
    2: IconBody,
    4: IconFace,
    6: IconHair,
    43: IconHairRemoval,
    37: IconMassage,
    42: IconMedicalDental,
    36: IconNails,
  },
  venue: {
    10: IconFace,
    43: IconBeard,
    48: IconSpa,
  },
};

export type Props = {
  size: BadgeSize;

  /**
   * Named pairs of background and foreground colours.
   * For example `ColorPairTeal` (the default) will use `ColorTeal100` and `ColorTeal10`.
   */
  colorPair: ColorPair;

  /**
   * Either the treatment type id or the venue type id, according to the category prop.
   *
   * If an id is not provided, or one is provided but it does not
   * correspond to a supported treatment type, then the AllTreatments
   * icon will be rendered.
   */
  id?: number;

  /**
   * The category name.
   */
  category?: 'treatment' | 'venue';
};

/**
 * TreatmentTypeBadge renders an IconBadge, with an appropriate icon
 * for a treatment type.
 */
export const TreatmentTypeBadge = ({
  colorPair = ColorPairTeal,
  id,
  size = 'lg',
  category = 'treatment',
}: Props) => {
  const Icon =
    id !== undefined && icons[category][id] !== undefined
      ? icons[category][id]
      : IconAllTreatments;
  const iconSize = size === 'sm' ? 16 : 24;

  return (
    <IconBadge
      colorPair={colorPair}
      size={size}
      icon={<Icon size={iconSize} />}
    />
  );
};

TreatmentTypeBadge.displayName = 'TreatmentTypeBadge';
